<template>
  <div class="offer-inquiry-viewer">
    <div class="text-subtitle-1 font-weight-bold my-2 mt-6 underline">
      {{ $t("order.offerInquiry") }}
      <span
        v-if="!isSuccessInquiry"
        class="red--text font-weight-bold"
      >
        ({{ $t("order.needToWaitUntilStokabReply") }})
      </span>
    </div>

    <v-row
      v-for="(item, index) in offerInquiryProducts"
      :key="index"
    >
      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.inquiryId") }}</label>
        <div>{{ item.inquiryId }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.inquiryNumber") }}</label>
        <div>{{ item.inquiryNumber }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.deliverydurationDays") }}</label>
        <div>{{ item.deliverydurationDays }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.offerValidUntilDate") }}</label>
        <div>{{ util.formatDateTime(item.offerValidUntilDate) }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.referenceId") }}</label>
        <div>{{ item.referenceId }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.responsiblePersonEmail") }}</label>
        <div>{{ item.responsiblePersonEmail }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.fromAddress") }}</label>
        <div>{{ getInquiryAddress(item.from) }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.fromPointId") }}</label>
        <div>{{ item.from && item.from.pointId }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.toAddress") }}</label>
        <div>{{ getInquiryAddress(item.to) }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.toPointId") }}</label>
        <div>{{ item.to && item.to.pointId }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.status") }}</label>
        <div class="red--text font-weight-bold">{{ item.status.state }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.productName") }}</label>
        <div>{{ item.product.name }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.productId") }}</label>
        <div>{{ item.product.productId }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.serviceId") }}</label>
        <div>{{ item.product.serviceId }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("order.connectionId") }}</label>
        <div>{{ item.product.connectionId }}</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("price.startCost") }}</label>
        <div>{{ item.product.price && item.product.price.oneTimeFee }} SEK</div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <label class="font-weight-bold">{{ $t("price.monthlyCost") }}</label>
        <div>{{ item.product.price && item.product.price.monthlyFee }} SEK</div>
      </v-col>

      <v-col
        cols="12"
        class="pb-0 underline mx-2 pt-2 mb-3"
        v-if="index !== offerInquiryProducts.length - 1"
      />
    </v-row>
  </div>
</template>

<script>
  import util from '@/utils'

  export default {
    props: {
      offerInquiryProducts: {
        type: Array,
        default: () => [],
      },
    },

    computed: {
      isSuccessInquiry() {
        return util.isSuccessInquiry(this.offerInquiryProducts)
      },
    },

    data() {
      return {
        util: util,
      }
    },

    methods: {
      getInquiryAddress(item) {
        const address = (item && item.address) || ''

        if (!address) {
          return ''
        }

        return util.getFullAddress({
          street: address.street,
          street_number: address.number,
          letter: address.littera,
          locality: address.city,
          postalcode: address.postalCode,
        })
      },
    },
  }
</script>
