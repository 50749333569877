<template>
  <base-card class="reply-request-price">
    <v-card-text>
      <v-row>
        <v-col cols="6" offset="3">
          <v-row>
            <v-col cols="6">
              <div class="text-subtitle-2">
                {{ $t("price.customerName") }}
              </div>
              <div class="value-field text-subtitle-1 black--text">
                {{ requestUserName }}
              </div>
            </v-col>

            <v-col cols="6">
              <div class="text-subtitle-2">
                {{ $t("common.email") }}
              </div>
              <div class="value-field text-subtitle-1 black--text">
                {{ requestInfo.requestUser && requestInfo.requestUser.email }}
              </div>
            </v-col>

            <v-col cols="6" v-if="companyName">
              <div class="text-subtitle-2">
                {{ $t("partner.companyName") }}
              </div>
              <div class="value-field text-subtitle-1 black--text">
                {{ companyName }}
              </div>
            </v-col>

            <v-col :cols="companyName ? 6 : 12">
              <div class="text-subtitle-2">
                {{ $t("address.address") }}
              </div>
              <div class="value-field text-subtitle-1 black--text">
                {{ fullAddress }}
              </div>
            </v-col>
          </v-row>

          <OfferInquiryViewer
            v-if="offerInquiryProducts.length > 0"
            :offer-inquiry-products="offerInquiryProducts"
          />
        </v-col>
      </v-row>

      <v-form
        ref="form"
        class="mt-4"
        v-model="validForm"
        lazy-validation
      >
        <div class="text-center text-h6 font-weight-bold">
          {{ replyPriceTitle }}
        </div>

        <v-row
          v-for="(request, index) in requestInfo.requestDetailList"
          :key="index"
          class="align-center px-10"
        >
          <v-col cols="12" md="2">
            <label :class="{ 'd-none': index !== 0 }">
              {{ $t("price.speed") }}
            </label>
            <div class="red--text font-weight-bold">{{ getSpeedTxt(request) }}</div>
            <label
              v-if="isPartnerAdminUser"
              class="partner-cost"
              :class="{ 'd-none': index !== 0 }"
            >{{ $t("price.partnerCost") }}</label>
          </v-col>

          <v-col cols="12" md="10" class="d-flex pa-0">
            <v-row>
              <v-col cols="2">
                <v-text-field
                  v-model="request.start"
                  :disabled="!isAvailableReply"
                  :label="$t('price.startCost')"
                  :rules="rules"
                  data-name="start"
                />
                <label
                  v-if="isPartnerAdminUser"
                  class="partner-cost"
                >{{ request.partnerStart }} SEK</label>
              </v-col>

              <v-col cols="2">
                <v-text-field
                  v-model="request.monthly"
                  :disabled="!isAvailableReply"
                  :label="$t('price.monthlyCost')"
                  :rules="rules"
                  data-name="monthly"
                />
                <label
                  v-if="isPartnerAdminUser"
                  class="partner-cost"
                >{{ request.partnerMonthly }} SEK</label>
              </v-col>

              <v-col cols="2">
                <v-text-field
                  v-model="request.premises"
                  :disabled="!isAvailableReply"
                  :label="$t('access.premises')"
                  :rules="rules"
                  data-name="premises"
                />

                <label
                  v-if="isPartnerAdminUser"
                  class="partner-cost"
                >{{ request.partnerPremises }} SEK</label>
              </v-col>

              <v-col cols="2">
                <v-menu
                  v-model="request.showCalenar"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="300"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="request.expectedDelivery"
                      :label="$t('price.expectedDelivery')"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="request.expectedDelivery"
                    @input="request.showCalenar = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  v-model="request.notes"
                  :disabled="!isAvailableReply"
                  :label="$t('price.notes')"
                  data-name="notes"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <div class="d-flex justify-center mt-4 mb-2">
          <v-btn
            class="primary px-10 mr-4"
            :disabled="!validForm"
            @click="replyRequest"
          >
            {{ $t("price.reply") }}
          </v-btn>

           <v-btn
            class="error px-10"
            @click="backForm"
          >
            {{ $t('common.back') }}
          </v-btn>
        </div>
       </v-form>
    </v-card-text>
  </base-card>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import util from '@/utils'
  import { showSuccessDialog, showErrorDialog } from '@/utils/swalDialog'
  import OfferInquiryViewer from '../order/OfferInquiryViewer'

  const aheadWeeks = 3

  export default {
    metaInfo: {
      title: 'Reply Request Price',
    },

    components: {
      OfferInquiryViewer
    },

    data() {
      return {
        requestId: '',
        requestInfo: {},
        validForm: true,
        rules: [
          value => !!value || this.$t('error.requiredField'),
        ],
      }
    },

    computed: {
      ...mapGetters({
        isAdminUser: 'isAdminUser',
        isPartnerAdminUser: 'isPartnerAdminUser',
      }),

      fullAddress() {
        return util.getFullAddress({
          street: this.requestInfo.street,
          street_number: this.requestInfo.number,
          letter: this.requestInfo.littera,
          locality: this.requestInfo.city,
          postalcode: this.requestInfo.postalCode,
        })
      },

      requestUserName() {
        if (!this.requestInfo || !this.requestInfo.requestUser) {
          return ''
        }

        const requestUser = this.requestInfo.requestUser

        return `${requestUser.firstName} ${requestUser.lastName}`
      },

      offerInquiryProducts() {
        return (
          (this.requestInfo &&
            this.requestInfo.offerInquiry &&
            this.requestInfo.offerInquiry.products) ||
          []
        )
      },

      isAdminReply() {
        if (this.isAdminUser) {
          return true
        }

        if (!this.requestInfo.requestUser?.partner) {
          return true
        }

        let isReplied = false
        for (const item of this.requestInfo.requestDetailList) {
          isReplied =
            (item.partnerStart && item.partnerMonthly && item.partnerPremises) ||
            isReplied
        }

        return isReplied
      },

      isAvailableReply() {
        if (this.isPartnerAdminUser && !this.isAdminReply) {
          return false
        }

        return util.isSuccessInquiry(this.offerInquiryProducts)
      },

      isRequestFromPartner() {
        return this.isAdminUser && this.requestInfo?.requestUser?.partner
      },

      companyName() {
        return this.requestInfo?.requestUser?.partner?.companyName
      },

      replyPriceTitle() {
        if (this.isRequestFromPartner) {
          return `${this.$t('price.manualPrice')} ${this.$t(
            'common.to'
          )} ${this.$t('partner.partner')}`
        }

        return `${this.$t('price.manualPrice')} ${this.$t('common.to')} ${this.$t(
          'partner.customer'
        )}`
      },
    },

    mounted() {
      this.requestId = this.$route.query.requestId
        ? this.$route.query.requestId
        : ''

      if (this.requestId) {
        this.loadRequestPrice()
      }
    },

    methods: {
      ...mapActions({
        fetchRequestPrice: 'fetchRequestPrice',
        replyPriceRequest: 'replyPriceRequest',
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
      }),

      async loadRequestPrice() {
        this.setLoading(true)
        this.setLoadingText(`${this.$t('common.loading')} ${this.$t(
          'common.data'
        )}...`)
        try {
          const response = await this.fetchRequestPrice(this.requestId)
          const requestDetailList = response.requestDetailList.map((item) => {
            var expectedDeliveryDate = new Date()
            expectedDeliveryDate.setDate(
              expectedDeliveryDate.getDate() + 7 * aheadWeeks
            )

            return {
              ...item,
              start: '',
              monthly: '',
              premises: '',
              expectedDelivery: util.formatDateTime(expectedDeliveryDate, 'YYYY-MM-DD'),
              showCalenar: false,
              notes: '',
            }
          })

          this.requestInfo = {
            ...response,
            requestDetailList,
          }
        } catch (error) {
          this.$router.push('/request-price-list')
        }
        this.setLoading(false)
      },

      async replyRequest() {
        const isValid = this.$refs.form.validate()

        if (!isValid) {
          return
        }

        const priceList = this.requestInfo.requestDetailList.map((item) => {
          let costData = {}
          if (this.isRequestFromPartner) {
            costData = {
              partnerStart: Number(item.start),
              partnerMonthly: Number(item.monthly),
              partnerPremises: Number(item.premises),
              start: 0,
              monthly: 0,
              premises: 0,
            }
          } else {
            costData = {
              start: Number(item.start),
              monthly: Number(item.monthly),
              premises: Number(item.premises),
            }
          }
          return {
            ...item,
            ...costData,
            expectedDelivery: util.formatDateTime(item.expectedDelivery, 'YYYY-MM-DD'),
            notes: item.notes,
          }
        })

        const payload = {
          requestId: this.requestId,
          priceList,
        }

        this.setLoading(true)
        this.setLoadingText(`${this.$t('price.replyingRequestPrice')}...`)
        try {
          await this.replyPriceRequest(payload)
          this.setLoading(false)

          await showSuccessDialog(this.$t('price.replyRequestSuccessfully'))
          this.$router.push('/request-price-list')
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)
      },

      getInquiryAddress(item) {
        const address = (item && item.address) || ''

        if (!address) {
          return ''
        }

        return util.getFullAddress({
          street: address.street,
          street_number: address.number,
          letter: address.littera,
          locality: address.city,
          postalcode: address.postalCode,
        })
      },

      getSpeedTxt(request) {
        const type = this.$t(`price.${request.type}`)
        return request.term
          ? this.$t('price.speedTxtWithMonth', {
              speed: request.speed,
              type,
              month: request.term,
            })
          : this.$t('price.speedTxt', { speed: request.speed, type })
      },

      backForm() {
        this.$router.push('/request-price-list')
      },
    },
  }
</script>

<style lang="scss">
  .reply-request-price {
    .value-field {
      min-height: 29px;
      border-style: solid;
      border-width: 0 0 thin 0;
      border-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.38) 0px, rgba(0, 0, 0, 0.38) 2px, transparent 2px, transparent 4px) 1 repeat;
    }

    .underline {
      border-style: solid;
      border-width: 0 0 thin 0;
      border-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.38) 0px, rgba(0, 0, 0, 0.38) 2px, transparent 2px, transparent 4px) 1 repeat;
    }
  }
</style>
